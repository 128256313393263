.text-category-item {
  font-weight: 500;
  font-size: 24px;
  font-family: var(--font-wixmadefordisplay);
  line-height: 30px;
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 2px solid transparent;
}
.category-list-container {
  @apply flex-grow;
  padding-left: 0px;
  padding-right: 0px;
}

@media (max-width: 1919px) {
  .text-category-item {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 1365px) {
  .category-list-container {
    @apply flex-grow;
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 1023px) {
  .category-list-container {
    @apply flex-grow;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 12px;
    max-width: 432px;
  }
}
@media (max-width: 768px) {
  .text-category-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .category-list-container {
    @apply flex-grow;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 12px;
    max-width: 340px;
  }
}

.text-category-item:hover,
.div-underline {
  border-bottom: 2px solid var(--dark-color);
  font-weight: 600;
}
